import { useState, useEffect, createContext } from "react";
import { IntlProvider } from "react-intl";
import Slovenian from "./sl.json";
import English from "./en.json";
import German from "./de.json";
import { LOCAL_STORAGE } from "../../constants/Constants";

export const LanguageContext = createContext();

export default function LanguageWrapper({ children }) {
  const [locale, setLocale] = useState(getLanguageForWebsite());
  const [messages, setMessages] = useState(getMessages(locale));

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE.LANGUAGE, JSON.stringify(locale));
  }, [locale]);

  function selectLanguage(newLocale) {
    if (newLocale === "sl") {
      setMessages(Slovenian);
      setLocale(newLocale);
    } else if (newLocale === "de") {
      setMessages(German);
      setLocale(newLocale);
    } else {
      setMessages(English);
      setLocale("en");
    }
  }

  return (
    <LanguageContext.Provider value={{ locale, selectLanguage }}>
      <IntlProvider messages={messages} locale={locale}>
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
}

function getLanguageForWebsite() {
  let languageFromLocalStorage = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE.LANGUAGE)
  );

  if (languageFromLocalStorage) {
    return languageFromLocalStorage;
  }

  const languageFromBrowser = (navigator.language || "en").split(/[-_]/)[0];

  if (
    languageFromBrowser === "sl" ||
    languageFromBrowser === "de" ||
    languageFromBrowser === "en"
  ) {
    return languageFromBrowser;
  }

  return "en";
}

function getMessages(locale) {
  if (locale === "sl") {
    return Slovenian;
  } else if (locale === "de") {
    return German;
  } else {
    return English;
  }
}
