import { TourProvider } from "@reactour/tour";
import Footer from "../../pages/fragments/Footer";
import Header from "../../pages/fragments/Header";
import Feedback from "../feedback/Feedback";
import { TOUR_STEPS_CLASSES } from "../../constants/Constants";
import { TourContext } from "../../wrappers/tour/TourWrapper";
import { useContext } from "react";
import { useIntl } from "react-intl";

export default function InternalPage({ children }) {
  const { setShowingTour } = useContext(TourContext);
  const intl = useIntl();

  let tourSteps = [
    {
      selector: TOUR_STEPS_CLASSES.STEP_1.selector,
      content: intl.formatMessage({ id: "tour.step1" }),
    },
    {
      selector: TOUR_STEPS_CLASSES.STEP_2.selector,
      content: intl.formatMessage({ id: "tour.step2" }),
    },
    {
      selector: TOUR_STEPS_CLASSES.STEP_3.selector,
      content: intl.formatMessage({ id: "tour.step3" }),
    },
    {
      selector: TOUR_STEPS_CLASSES.STEP_4.selector,
      content: intl.formatMessage({ id: "tour.step4" }),
    },
  ];

  return (
    <TourProvider
      steps={tourSteps}
      badgeContent={({ totalSteps, currentStep }) =>
        currentStep + 1 + "/" + totalSteps
      }
      styles={{
        popover: (base) => ({
          ...base,
          "--reactour-accent": "#4f46e5",
        }),
        maskArea: (base) => ({ ...base }),
        maskWrapper: (base) => ({ ...base }),
        badge: (base) => ({ ...base, right: "auto", left: "-0.8125em" }),
        controls: (base) => ({ ...base }),
        close: (base) => ({ ...base, left: "auto", right: 8, top: 8 }),
      }}
      onClickClose={({ setIsOpen }) => {
        setShowingTour(false);
        setIsOpen(false);
      }}
      onClickMask={({ setIsOpen }) => {
        setShowingTour(false);
        setIsOpen(false);
      }}
    >
      <Header />
      <main className="bg-white shadow flex-1">
        {children}
        <Feedback />
      </main>
      <Footer />
    </TourProvider>
  );
}
