import FAQ from "../../components/faq/FAQ";
import { HalfWidthPageWrapper } from "../../components/page-wrappers/PageWrappers";

export default function FAQPage() {
  return (
    <HalfWidthPageWrapper>
      <FAQ />
    </HalfWidthPageWrapper>
  );
}
