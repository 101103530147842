import { FormattedMessage } from "react-intl";
import { HalfWidthPageWrapper } from "../../components/page-wrappers/PageWrappers";
import { joinClasses } from "../../helpers/Utils";

const items = [
  {
    title: null,
    sections: [
      {
        title: null,
        content: [
          { type: "paragraph", key: "privacy.policy.intro.p1" },
          { type: "paragraph", key: "privacy.policy.intro.p2" },
        ],
      },
    ],
  },
  {
    title: "privacy.policy.data.collection",
    sections: [
      {
        title: null,
        content: [
          { type: "paragraph", key: "privacy.policy.data.collection.p1" },
          {
            type: "list",
            keys: [
              "privacy.policy.data.collection.l1",
              "privacy.policy.data.collection.l2",
            ],
          },
        ],
      },
    ],
  },
  {
    title: "privacy.policy.legal.basis",
    sections: [
      {
        title: null,
        content: [{ type: "paragraph", key: "privacy.policy.legal.basis.p1" }],
      },
    ],
  },
  {
    title: "privacy.policy.data.usage",
    sections: [
      {
        title: null,
        content: [
          { type: "paragraph", key: "privacy.policy.data.usage.p1" },
          {
            type: "list",
            keys: [
              "privacy.policy.data.usage.l1",
              "privacy.policy.data.usage.l2",
              "privacy.policy.data.usage.l3",
            ],
          },
        ],
      },
    ],
  },
  {
    title: "privacy.policy.data.storage",
    sections: [
      {
        title: null,
        content: [{ type: "paragraph", key: "privacy.policy.data.storage.p1" }],
      },
    ],
  },
  {
    title: "privacy.policy.sharing.of.data",
    sections: [
      {
        title: null,
        content: [
          { type: "paragraph", key: "privacy.policy.sharing.of.data.p1" },
          {
            type: "list",
            keys: [
              "privacy.policy.sharing.of.data.l1",
              "privacy.policy.sharing.of.data.l2",
            ],
          },
          { type: "paragraph", key: "privacy.policy.sharing.of.data.p2" },
        ],
      },
    ],
  },
  {
    title: "privacy.policy.user.rights",
    sections: [
      {
        title: null,
        content: [{ type: "paragraph", key: "privacy.policy.user.rights.p1" }],
      },
    ],
  },
  {
    title: "privacy.policy.cookies",
    sections: [
      {
        title: null,
        content: [{ type: "paragraph", key: "privacy.policy.cookies.p1" }],
      },
    ],
  },
  {
    title: "privacy.policy.marketing",
    sections: [
      {
        title: null,
        content: [{ type: "paragraph", key: "privacy.policy.marketing.p1" }],
      },
    ],
  },
  {
    title: "privacy.policy.data.retention",
    sections: [
      {
        title: null,
        content: [
          { type: "paragraph", key: "privacy.policy.data.retention.p1" },
        ],
      },
    ],
  },
  {
    title: "privacy.policy.international.data.transfers",
    sections: [
      {
        title: null,
        content: [
          {
            type: "paragraph",
            key: "privacy.policy.international.data.transfers.p1",
          },
        ],
      },
    ],
  },
  {
    title: "privacy.policy.us.residents",
    sections: [
      {
        title: null,
        content: [
          {
            type: "paragraph",
            key: "privacy.policy.us.residents.p1",
          },
        ],
      },
    ],
  },
  {
    title: "privacy.policy.eu.compliance",
    sections: [
      {
        title: null,
        content: [
          { type: "paragraph", key: "privacy.policy.eu.compliance.p1" },
        ],
      },
    ],
  },
  {
    title: "privacy.policy.updates",
    sections: [
      {
        title: null,
        content: [{ type: "paragraph", key: "privacy.policy.updates.p1" }],
      },
    ],
  },
  {
    title: "privacy.policy.contact",
    sections: [
      {
        title: null,
        content: [
          { type: "paragraph", key: "privacy.policy.contact.p1" },
          {
            type: "list",
            keys: [
              "privacy.policy.contact.l1",
              "privacy.policy.contact.l2",
              "privacy.policy.contact.l3",
            ],
            values: {
              link: (
                <a href="mailto:support@yumalarm.com">support@yumalarm.com</a>
              ),
            },
            noDots: true,
          },
        ],
      },
    ],
  },
];

function renderItem(item) {
  return (
    <>
      {item.title && (
        <h2 className="mt-6 text-2xl font-bold tracking-tight text-gray-900">
          <FormattedMessage id={item.title} />
        </h2>
      )}
      {item.sections.map((section) => {
        return renderSection(section);
      })}
    </>
  );
}

function renderSection(section) {
  return (
    <>
      {section.title && (
        <h3 className="mt-6 text-xl font-bold tracking-tight text-gray-900">
          <FormattedMessage id={section.title} />
        </h3>
      )}
      {section.content.map((content, idx) => {
        return renderContent(content, idx);
      })}
    </>
  );
}

function renderContent(content, idx) {
  if (content.type === "list") {
    return (
      <ul
        className={joinClasses(
          "mt-2 space-y-4 text-gray-600 ml-4",
          content.noDots ? "" : "list-disc"
        )}
      >
        {content.keys.map((listKey) => {
          return (
            <li className="gap-x-3">
              <span>
                <FormattedMessage id={listKey} values={content.values} />
              </span>
            </li>
          );
        })}
      </ul>
    );
  } else {
    if (idx === 0) {
      return (
        <p className="mt-6 leading-8">
          <FormattedMessage id={content.key} values={content.values} />
        </p>
      );
    } else {
      return (
        <p className="mt-2 leading-8">
          <FormattedMessage id={content.key} values={content.values} />
        </p>
      );
    }
  }
}

export default function PrivacyPolicyPage() {
  return (
    <HalfWidthPageWrapper>
      <div className="mx-auto max-w-4xl text-base leading-7 text-gray-700">
        <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          <FormattedMessage id="privacy.policy" />
        </h1>
        <p className="text-base font-semibold leading-7 text-gray-700">
          <FormattedMessage id="privacy.policy.version" />
        </p>
        {items.map((item) => {
          return renderItem(item);
        })}
      </div>
    </HalfWidthPageWrapper>
  );
}
