import { useState, useEffect, createContext } from "react";
import { fetchAuthSession, fetchUserAttributes } from "aws-amplify/auth";
import { useAuthenticator } from "@aws-amplify/ui-react";
import {
  getReferralCode,
  getReferralCount,
  getUserCategoriesLimit,
  getUserMealsLimit,
  getUserNotificationsLimit,
  isUserSubscribed,
} from "../../helpers/SubscriptionHelper";

export const UserContext = createContext();

export default function UserDataWrapper({ children }) {
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [idToken, setIdToken] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  let isLoggedIn = user !== null;
  let categoryLimit = getUserCategoriesLimit(user);
  let mealLimit = getUserMealsLimit(user);
  let notificationLimit = getUserNotificationsLimit(user);
  let referralCode = getReferralCode(user);
  let referralCount = getReferralCount(user);
  let email = user?.email;
  let sub = user?.sub;
  let hasValidSubscription = isUserSubscribed(user);

  async function loadAttributes() {
    setLoading(true);
    if (authStatus === "authenticated") {
      try {
        const response = await fetchUserAttributes();
        setUser({ ...response });
        const authSessionResponse = await fetchAuthSession();
        setAccessToken(authSessionResponse.tokens?.accessToken);
        setIdToken(authSessionResponse.tokens?.idToken);
      } catch (error) {
        setUser(null);
        setAccessToken(null);
        setIdToken(null);
      }
    } else if (authStatus === "unauthenticated") {
      setUser(null);
      setAccessToken(null);
      setIdToken(null);
    }
    setLoading(false);
  }

  useEffect(() => {
    loadAttributes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authStatus]);

  async function refreshUser() {
    loadAttributes();
  }

  function signOutUser() {
    setUser(null);
  }

  return (
    <UserContext.Provider
      value={{
        isLoggedIn,
        categoryLimit,
        mealLimit,
        notificationLimit,
        referralCode,
        referralCount,
        email,
        sub,
        accessToken,
        idToken,
        hasValidSubscription,
        loading,
        refreshUser,
        signOutUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
