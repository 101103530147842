import List from "../../components/list/List";
import { useIntl } from "react-intl";
import { HalfWidthPageWrapper } from "../../components/page-wrappers/PageWrappers";
import useRestApi from "../../custom-hooks/useRestApi";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { useState } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import {
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

export default function ListCategoriesPage() {
  const intl = useIntl();
  const { executeGetCall, executeDeleteCall } = useRestApi();
  const [refreshKey, setRefreshKey] = useState(0);
  const [open, setOpen] = useState(false);
  const [categoryIdToDelete, setCategoryIdToDelete] = useState(null);

  async function handleFetchItems(nextToken) {
    let queryParams = { limit: 10 };
    if (nextToken) {
      queryParams.token = JSON.stringify(nextToken);
    }

    let { body } = await executeGetCall("categories", { queryParams });

    return {
      items: body.items,
      token: body.nextToken ? body.nextToken : null,
    };
  }

  async function removeCategoryWithId(categoryId) {
    if (!categoryId) {
      return;
    }
    await executeDeleteCall("categories/" + categoryId);
    setRefreshKey((prevKey) => prevKey + 1);
  }

  const labels = (category) => [
    {
      value: category.name,
    },
  ];

  const actions = (category) => [
    {
      element: (
        <Link to={"view/" + category.sharedId}>
          <FormattedMessage id="View" />
        </Link>
      ),
      textClasses: "text-indigo-600 hover:text-indigo-900",
    },
    {
      element: (
        <Link to={"edit/" + category.sharedId}>
          <FormattedMessage id="Edit" />
        </Link>
      ),
      textClasses: "text-indigo-600 hover:text-indigo-900",
    },
    {
      element: (
        <Link
          onClick={() => {
            setCategoryIdToDelete(category.sharedId);
            setOpen(true);
          }}
        >
          <FormattedMessage id="Delete" />
        </Link>
      ),
      textClasses: "text-red-600 hover:text-red-900",
    },
    {
      element: (
        <Link to={"share/" + category.sharedId}>
          <FormattedMessage id="Share" />
        </Link>
      ),
      textClasses: "text-indigo-600 hover:text-indigo-900",
    },
  ];

  function DeleteCategoryModal() {
    return (
      <Dialog open={open} onClose={setOpen} className="relative z-10">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                <button
                  type="button"
                  onClick={() => {
                    setCategoryIdToDelete(null);
                    setOpen(false);
                  }}
                  className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <span className="sr-only">Close</span>
                  <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                </button>
              </div>
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationTriangleIcon
                    aria-hidden="true"
                    className="h-6 w-6 text-red-600"
                  />
                </div>
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <DialogTitle
                    as="h3"
                    className="text-base font-semibold leading-6 text-gray-900"
                  >
                    <FormattedMessage id="category.delete.modal.title" />
                  </DialogTitle>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      <FormattedMessage id="category.delete.modal.description" />
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  onClick={() => {
                    removeCategoryWithId(categoryIdToDelete);
                    setOpen(false);
                  }}
                  className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                >
                  <FormattedMessage id="Delete" />
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setCategoryIdToDelete(null);
                    setOpen(false);
                  }}
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                >
                  <FormattedMessage id="Cancel" />
                </button>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    );
  }

  return (
    <HalfWidthPageWrapper>
      <List
        title={intl.formatMessage({ id: "Categories" })}
        emptyListTitle={intl.formatMessage({
          id: "empty.list.categories.title",
        })}
        emptyListDescription={intl.formatMessage({
          id: "empty.list.categories.description",
        })}
        emptyListNewText={intl.formatMessage({
          id: "empty.list.categories.new",
        })}
        fetchItemsMethod={handleFetchItems}
        actions={actions}
        labels={labels}
        refreshKey={refreshKey}
      />
      <DeleteCategoryModal />
    </HalfWidthPageWrapper>
  );
}
