"use client";

import { useContext, useState } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import {
  LightBulbIcon,
  BugAntIcon,
  InboxArrowDownIcon,
} from "@heroicons/react/24/outline";
import { ChatBubbleBottomCenterTextIcon } from "@heroicons/react/24/solid";
import { FormattedMessage, useIntl } from "react-intl";
import { UserContext } from "../../wrappers/user/UserDataWrapper";
import useRestApi from "../../custom-hooks/useRestApi";
import { toast } from "react-toastify";

export default function Feedback() {
  const intl = useIntl();
  const { isLoggedIn } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [showDetailsPage, setShowDetailsPage] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [feedback, setFeedback] = useState({
    title: "",
    description: "",
    type: "",
  });
  const { executePostCall } = useRestApi();

  async function handleSendClick() {
    const validationResult = validateFeedback();

    if (!validationResult.isValid) {
      setValidationErrors(validationResult.validationErrors);
      return;
    }

    const { statusCode } = await executePostCall("feedback", {
      body: {
        title: feedback.title,
        description: feedback.description,
        type: feedback.type,
      },
    });

    if (statusCode === 200) {
      setOpen(false);
      toast.success(intl.formatMessage({ id: "feedback.modal.success" }));
    }
  }

  function validateFeedback() {
    let isValid = true;
    let validationErrors = {};

    if (!feedback.title) {
      isValid = false;
      validationErrors["title"] = intl.formatMessage({
        id: "feedback.modal.title.validation.empty",
      });
    }

    if (!feedback.description) {
      isValid = false;
      validationErrors["description"] = intl.formatMessage({
        id: "feedback.modal.description.validation.empty",
      });
    }

    return { isValid: isValid, validationErrors: validationErrors };
  }

  function renderFeedbackForm() {
    return (
      <form>
        <div className="space-y-12">
          <div className="border-b border-gray-900/10 pb-12">
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-full">
                <label
                  htmlFor="feedback-title"
                  className="block text-sm/6 font-medium text-gray-900"
                >
                  <FormattedMessage id="Title" />
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
                    <input
                      id="feedback-title"
                      name="feedback-title"
                      type="text"
                      value={feedback.title}
                      onChange={(event) => {
                        setValidationErrors({
                          ...validationErrors,
                          title: null,
                        });
                        setFeedback({ ...feedback, title: event.target.value });
                      }}
                      className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm/6"
                    />
                  </div>
                  {validationErrors["title"] && (
                    <p className="mt-2 text-sm text-red-600">
                      {validationErrors["title"]}
                    </p>
                  )}
                </div>
              </div>

              <div className="col-span-full">
                <label
                  htmlFor="feedback-description"
                  className="block text-sm/6 font-medium text-gray-900"
                >
                  <FormattedMessage id="Description" />
                </label>
                <div className="mt-2">
                  <textarea
                    id="feedback-description"
                    name="feedback-description"
                    rows={3}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                    value={feedback.description}
                    onChange={(event) => {
                      setValidationErrors({
                        ...validationErrors,
                        description: null,
                      });
                      setFeedback({
                        ...feedback,
                        description: event.target.value,
                      });
                    }}
                  />
                </div>
                {validationErrors["description"] && (
                  <p className="mt-2 text-sm text-red-600">
                    {validationErrors["description"]}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="button"
            className="rounded-md bg-indigo-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={handleSendClick}
          >
            <FormattedMessage id="Send" />
          </button>
        </div>
      </form>
    );
  }

  if (!isLoggedIn) {
    return null;
  }

  return (
    <>
      <div className="fixed bottom-24 right-4">
        <ChatBubbleBottomCenterTextIcon
          onClick={() => {
            setOpen(true);
            setShowDetailsPage(false);
            setFeedback({
              title: "",
              description: "",
              type: "",
            });
            setValidationErrors({});
          }}
          aria-hidden="true"
          className="h-10 w-10 text-white bg-indigo-600 rounded-full p-2 hover:w-11 hover:h-11"
        />
      </div>

      <Dialog open={open} onClose={setOpen} className="relative z-10">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white p-4 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-sm sm:p-6 sm:pt-2 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              <div>
                {!showDetailsPage && (
                  <div className="mt-3 sm:mt-5">
                    <DialogTitle
                      as="h3"
                      className="text-base font-semibold text-gray-900"
                    >
                      <FormattedMessage id="feedback.modal.title" />
                    </DialogTitle>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        <FormattedMessage id="feedback.modal.description" />
                      </p>
                    </div>
                  </div>
                )}

                {showDetailsPage && (
                  <div className="mt-3 sm:mt-5">
                    <DialogTitle
                      as="h3"
                      className="text-base font-semibold text-gray-900"
                    >
                      <FormattedMessage
                        id={`feedback.modal.title.${feedback.type}`}
                      />
                    </DialogTitle>
                  </div>
                )}
              </div>

              {!showDetailsPage && (
                <div className="mt-3 sm:mt-5">
                  <FeedbackModalButton
                    messageKey="feedback.modal.option.feature"
                    clickHandler={() => {
                      setFeedback({ ...feedback, type: "Feature" });
                      setShowDetailsPage(true);
                    }}
                    Icon={LightBulbIcon}
                  />
                  <FeedbackModalButton
                    messageKey="feedback.modal.option.feedback"
                    clickHandler={() => {
                      setFeedback({ ...feedback, type: "Feedback" });
                      setShowDetailsPage(true);
                    }}
                    Icon={InboxArrowDownIcon}
                  />
                  <FeedbackModalButton
                    messageKey="feedback.modal.option.bug"
                    clickHandler={() => {
                      setFeedback({ ...feedback, type: "Bug" });
                      setShowDetailsPage(true);
                    }}
                    Icon={BugAntIcon}
                  />
                </div>
              )}

              {showDetailsPage && renderFeedbackForm()}
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </>
  );
}

function FeedbackModalButton({ messageKey, clickHandler, Icon }) {
  return (
    <button
      type="button"
      onClick={clickHandler}
      className="inline-flex w-full items-center justify-center rounded-md bg-indigo-600 mt-2 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
    >
      <Icon
        aria-hidden="true"
        className="h-4 w-4 text-white rounded-full m-2"
      />
      <FormattedMessage id={messageKey} />
    </button>
  );
}
