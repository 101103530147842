import { FullWidthPageWrapper } from "../../components/page-wrappers/PageWrappers";
import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import logo from "../../assets/images/logo_black.png";
import { useContext, useState } from "react";
import useRestApi from "../../custom-hooks/useRestApi";
import validator from "validator";
import heroImage from "../../assets/images/landing-v2/landing_page_v2_hero.png";
import introImage from "../../assets/images/landing-v2/landing_page_v2_introduction.png";
import mealImageEN from "../../assets/images/landing-v2/landing_v2_meals_en.png";
import mealImageDE from "../../assets/images/landing-v2/landing_v2_meals_de.png";
import mealImageSL from "../../assets/images/landing-v2/landing_v2_meals_sl.png";
import notificationImageEN from "../../assets/images/landing-v2/landing_v2_notification_en.png";
import notificationImageDE from "../../assets/images/landing-v2/landing_v2_notification_de.png";
import notificationImageSL from "../../assets/images/landing-v2/landing_v2_notification_sl.png";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import LanguageDropdown from "../fragments/LanguageDropdown";
import { LanguageContext } from "../../wrappers/language/LanguageWrapper";

function getMealImage(locale) {
  switch (locale) {
    case "de":
      return mealImageDE;
    case "sl":
      return mealImageSL;
    default:
      return mealImageEN;
  }
}

function getNotificationImage(locale) {
  switch (locale) {
    case "de":
      return notificationImageDE;
    case "sl":
      return notificationImageSL;
    default:
      return notificationImageEN;
  }
}

export default function LandingPage() {
  const [email, setEmail] = useState("");
  const [validationError, setValidationError] = useState("");
  const [blockButton, setBlockButton] = useState(false);
  const { executePostCallWithoutAuth } = useRestApi();
  const intl = useIntl();
  const [openSuccessModal, setOpenSuccessModal] = useState(false);

  async function handleSaveEmail() {
    setBlockButton(true);

    if (!validator.isEmail(email)) {
      setValidationError(
        intl.formatMessage({
          id: "e-mail.validation",
        })
      );
      setBlockButton(false);
      return;
    }

    let { statusCode } = await executePostCallWithoutAuth(`landing`, {
      body: {
        email,
      },
    });

    if (statusCode === 200) {
      setOpenSuccessModal(true);
      setEmail("");
    }

    setBlockButton(false);
  }

  return (
    <>
      <FullWidthPageWrapper>
        <LandingHeader />
        <main className="relative">
          <LandingPageHero
            email={email}
            setEmail={setEmail}
            buttonHandler={handleSaveEmail}
            validationError={validationError}
            setValidationError={setValidationError}
            blockButton={blockButton}
          />
          <LandingFeature1 />
          <LandingFeature2 />
          <Introduction />
          <LandingCTA
            email={email}
            setEmail={setEmail}
            buttonHandler={handleSaveEmail}
            validationError={validationError}
            setValidationError={setValidationError}
            blockButton={blockButton}
          />
        </main>
        <LandingFooter />
        <SuccessModal open={openSuccessModal} setOpen={setOpenSuccessModal} />
      </FullWidthPageWrapper>
    </>
  );
}

function LandingHeader() {
  return (
    <nav className="fixed top-0 left-0 right-0 z-50 h-full max-h-40 md:max-h-24 bg-white">
      <div className="md:px-24 w-full h-full flex items-center justify-center md:justify-between flex-col space-y-6 md:space-y-0 md:flex-row">
        <Link className="flex items-center" to="/">
          <img className="h-full" src={logo} alt="YumAlarm logo" />
        </Link>
        <span className="flex items-center space-x-10">
          <Link
            to="/login"
            className="block rounded-lg text-center text-md text-black  focus-visible:outline-indigo-600"
          >
            <FormattedMessage id="Login" />
          </Link>
          <a
            href="#cta"
            className="block rounded-lg bg-black p-3 text-center text-lg font-semibold text-white outline outline-4 outline-slate-300 hover:bg-indigo-600  focus-visible:outline-indigo-600"
          >
            <FormattedMessage id="landing.page.cta.button.text" />
          </a>
        </span>
      </div>
    </nav>
  );
}

function LandingPageHero({
  email,
  setEmail,
  blockButton,
  validationError,
  setValidationError,
  buttonHandler,
}) {
  return (
    <section className="flex items-center min-h-screen px-6 md:px-24 py-48 md:py-24">
      <div className="mx-auto w-full h-full grid md:grid-cols-2 gap-12 items-center">
        {/* Right Column - Content */}
        <div className="text-left space-y-8">
          <h1 className="text-4xl md:text-6xl font-bold">
            <FormattedMessage id="landing.page.v2.hero.title" />
          </h1>
          <p className="text-lg text-gray-600 max-w-xl">
            <FormattedMessage id="landing.page.v2.hero.description" />
          </p>
          <div className="flex flex-col sm:flex-row gap-4 items-center">
            <span className="max-w-md w-full">
              <input
                type="email"
                placeholder="Enter your email"
                className="max-w-md w-full flex h-10 rounded-md border border-input bg-background p-3 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                value={email}
                onChange={(e) => {
                  setValidationError(null);
                  setEmail(e.target.value);
                }}
              />
              {validationError && (
                <span className="text-red-600 text-sm">{validationError}</span>
              )}
            </span>
            <button
              type="button"
              onClick={buttonHandler}
              disabled={blockButton}
              className="block rounded-lg bg-black p-3 text-center text-lg font-semibold text-white outline outline-4 outline-slate-300 hover:bg-indigo-600  focus-visible:outline-indigo-600"
            >
              <FormattedMessage id="landing.page.cta.button.text" />
            </button>
          </div>
        </div>

        {/* Left Column - App Preview */}
        <div className="relative">
          <div className="relative w-full aspect-[4/3] rounded-2xl overflow-hidden bg-white/80 backdrop-blur-md shadow-lg">
            <img
              src={heroImage}
              alt="App Preview"
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-gradient-to-tr from-indigo-600/10 to-transparent"></div>
          </div>
          <div className="absolute -inset-0.5 bg-gradient-to-tr from-indigo-600/20 to-transparent blur-2xl -z-10"></div>
        </div>
      </div>
    </section>
  );
}

function LandingFeature1() {
  const { locale } = useContext(LanguageContext);

  return (
    <section className="w-full p-6 md:p-24">
      <div className="mx-auto w-full grid md:grid-cols-2 gap-12 items-center">
        {/* Left Column - Image */}
        <div className="relative order-2 md:order-1">
          <div className="relative w-full aspect-[4/3] rounded-2xl overflow-hidden bg-white/80 backdrop-blur-md shadow-lg">
            <img
              src={getMealImage(locale)}
              alt="YumALarm meals feature preview"
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-gradient-to-tr from-indigo-600/10 to-transparent"></div>
          </div>
          <div className="absolute -inset-0.5 bg-gradient-to-tr from-indigo-600/20 to-transparent blur-2xl -z-10"></div>
        </div>

        {/* Right Column - Content */}
        <div className="text-left space-y-8 order-1 md:order-2">
          <h2 className="text-3xl md:text-5xl font-bold">
            <FormattedMessage id="landing.page.v2.feature1.title" />
          </h2>
          <p className="text-lg text-gray-600 max-w-xl">
            <FormattedMessage id="landing.page.v2.feature1.description" />
          </p>
        </div>
      </div>
    </section>
  );
}

function LandingFeature2() {
  const { locale } = useContext(LanguageContext);

  return (
    <section className="w-full p-6 md:p-24">
      <div className="mx-auto w-full grid md:grid-cols-2 gap-12 items-center">
        {/* Left Column - Content */}
        <div className="text-left space-y-8 order-1">
          <h2 className="text-3xl md:text-5xl font-bold">
            <FormattedMessage id="landing.page.v2.feature2.title" />
          </h2>
          <p className="text-lg text-gray-600 max-w-xl">
            <FormattedMessage id="landing.page.v2.feature2.description" />
          </p>
        </div>

        {/* Right Column - Image */}
        <div className="relative order-2">
          <div className="relative w-full aspect-[4/3] rounded-2xl overflow-hidden bg-white/80 backdrop-blur-md shadow-lg">
            <img
              src={getNotificationImage(locale)}
              alt="YumAlarm notification feature preview"
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-gradient-to-tr from-indigo-600/10 to-transparent"></div>
          </div>
          <div className="absolute -inset-0.5 bg-gradient-to-tr from-indigo-600/20 to-transparent blur-2xl -z-10"></div>
        </div>
      </div>
    </section>
  );
}

function Introduction() {
  return (
    <section className="w-full p-6 md:p-24">
      <div className="max-w-4xl mx-auto">
        <div className="p-8 md:p-12 space-y-8 bg-white/80 backdrop-blur-md rounded-lg shadow-sm">
          <div className="flex flex-col md:flex-row gap-8 items-start">
            <div className="w-64 h-48 shrink-0 mx-auto md:mx-0">
              <div className="relative w-full h-full rounded-xl overflow-hidden">
                <img
                  src={introImage}
                  alt="Family image"
                  className="w-full h-full object-cover"
                />
                <div className="absolute inset-0 bg-gradient-to-tr from-indigo-600/10 to-transparent"></div>
              </div>
            </div>
            <div className="space-y-6">
              <h2 className="text-3xl md:text-4xl font-bold">
                <FormattedMessage id="landing.page.v2.intro.title" />
              </h2>
              <div className="space-y-4 text-gray-600">
                <p>
                  <FormattedMessage id="landing.page.v2.intro.description.part1" />
                </p>
                <p>
                  <FormattedMessage id="landing.page.v2.intro.description.part2" />
                </p>
                <p>
                  <FormattedMessage id="landing.page.v2.intro.description.part3" />
                </p>
                <p>
                  <FormattedMessage id="landing.page.v2.intro.description.part4" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function LandingCTA({
  email,
  setEmail,
  blockButton,
  validationError,
  setValidationError,
  buttonHandler,
}) {
  return (
    <section id="cta" className="w-full p-6 md:p-24">
      <div className="w-full mx-auto text-center animate-on-scroll">
        <span className="inline-block px-4 py-1.5 mb-6 text-sm font-medium rounded-full bg-indigo-100 text-indigo-600">
          <FormattedMessage id="landing.page.v2.cta.eyebrow" />
        </span>
        <h2 className="text-3xl md:text-4xl font-bold mb-6">
          <FormattedMessage id="landing.page.v2.cta.title" />
        </h2>
        <p className="text-gray-600 max-w-2xl mx-auto mb-10">
          <FormattedMessage id="landing.page.v2.cta.description" />
        </p>
        <div className="flex flex-col sm:flex-row gap-4 justify-center items-center w-full mx-auto">
          <span className="max-w-md w-full">
            <input
              type="email"
              placeholder="Enter your email"
              className="max-w-md w-full flex h-10 rounded-md border border-input bg-background p-3 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
              value={email}
              onChange={(e) => {
                setValidationError(null);
                setEmail(e.target.value);
              }}
            />
            {validationError && (
              <span className="text-red-600 text-sm">{validationError}</span>
            )}
          </span>
          <button
            type="button"
            onClick={buttonHandler}
            disabled={blockButton}
            className="block rounded-lg bg-black p-3 text-center text-lg font-semibold text-white outline outline-4 outline-slate-300 hover:bg-indigo-600  focus-visible:outline-indigo-600"
          >
            <FormattedMessage id="landing.page.cta.button.text" />
          </button>
        </div>
      </div>
    </section>
  );
}

function LandingFooter() {
  return (
    <footer className="max-w-6xl mx-auto px-6 py-8 border-t border-gray-200/10">
      <div className="flex flex-col sm:flex-row justify-between items-center">
        <div className="text-sm text-gray-500 pb-3 sm:mb-0">
          &copy; <FormattedMessage id="footer.copyright" />
        </div>
        <div className="container mx-auto flex justify-center">
          <LanguageDropdown whiteText={false} />
        </div>
        <div className="flex">
          <Link
            to="/terms-of-service"
            className="px-2 md:px-6 text-sm text-gray-500 hover:text-gray-700"
          >
            <FormattedMessage id="footer.terms" />
          </Link>
          <Link
            to="/privacy-policy"
            className="px-2 md:px-6 text-sm text-gray-500 hover:text-gray-700"
          >
            <FormattedMessage id="footer.privacy" />
          </Link>
        </div>
      </div>
    </footer>
  );
}

function SuccessModal({ open, setOpen }) {
  return (
    <Dialog open={open} onClose={setOpen} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-sm sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div>
              <div className="mx-auto flex size-12 items-center justify-center rounded-full bg-green-100">
                <CheckIcon
                  aria-hidden="true"
                  className="size-6 text-green-600"
                />
              </div>
              <div className="mt-3 text-center sm:mt-5">
                <DialogTitle
                  as="h3"
                  className="text-base font-semibold text-gray-900"
                >
                  <FormattedMessage id="landing.page.v2.cta.success.title" />
                </DialogTitle>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    <FormattedMessage id="landing.page.v2.cta.success.description" />
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-6">
              <button
                type="button"
                onClick={() => setOpen(false)}
                className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                <FormattedMessage id="landing.page.v2.cta.success.button" />
              </button>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
