import { FormattedMessage } from "react-intl";

export default function TextAreaInput({
  labelTextKey,
  id,
  validationError,
  value,
  handleOnChange,
  disabled = false,
  rows = 3,
  cols,
  instructionLabelKey,
}) {
  let extraClasses = " text-gray-900 ring-gray-300";

  if (validationError) {
    extraClasses = " text-red-900 ring-red-300";
  }
  if (disabled) {
    extraClasses = " cursor-not-allowed bg-gray-50 text-gray-400 ring-gray-300";
  }

  return (
    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
      <label
        htmlFor={id}
        className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
      >
        <FormattedMessage id={labelTextKey} />
      </label>
      <div className="mt-2 sm:col-span-2 sm:mt-0">
        <textarea
          name={id}
          id={id}
          className={
            "block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 " +
            extraClasses
          }
          value={value}
          onChange={handleOnChange}
          disabled={disabled}
          rows={rows}
          cols={cols}
        />
        {validationError && (
          <p className="mt-2 text-sm text-red-600" id="category-name-error">
            {validationError}
          </p>
        )}
        {instructionLabelKey && (
          <p className="mt-3 text-sm leading-6 text-gray-600">
            <FormattedMessage id={instructionLabelKey} />
          </p>
        )}
      </div>
    </div>
  );
}
