import { FormattedMessage } from "react-intl";
import { useContext } from "react";
import { LanguageContext } from "../../wrappers/language/LanguageWrapper";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from "../../components/ui-kit/dropdown";
import { ChevronDownIcon } from "@heroicons/react/16/solid";

export default function LanguageDropdown({ whiteText = true }) {
  const { selectLanguage } = useContext(LanguageContext);

  return (
    <Dropdown>
      <DropdownButton color={whiteText ? "white" : "gray"}>
        <FormattedMessage id="language.select" />
        <ChevronDownIcon />
      </DropdownButton>
      <DropdownMenu>
        <DropdownItem onClick={() => selectLanguage("en")}>
          <FormattedMessage id="language.selector.english" />
        </DropdownItem>
        <DropdownItem onClick={() => selectLanguage("sl")}>
          <FormattedMessage id="language.selector.slovenian" />
        </DropdownItem>
        <DropdownItem onClick={() => selectLanguage("de")}>
          <FormattedMessage id="language.selector.german" />
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}
