export function FullWidthPageWrapper({children}) {
    return <div className="w-full">
        {children}
    </div>
}

export function HalfWidthPageWrapper({children}) {
    return <div className="w-full mx-auto max-w-6xl p-8 sm:p-10 lg:p-14">
        {children}
    </div>
}