import React from "react";
import { FormattedMessage } from "react-intl";
import { joinClasses } from "../../../helpers/Utils";

export default function SelectInput({ id, setValue, value, isDisabled }) {
  return (
    <div
      className={joinClasses(
        "relative flex gap-x-3",
        isDisabled ? "cursor-not-allowed" : ""
      )}
      key={"key-" + id}
    >
      <div className="flex h-6 items-center">
        <input
          type="checkbox"
          id={id}
          name={id}
          checked={value}
          onChange={setValue}
          disabled={isDisabled}
          className={joinClasses(
            "h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600",
            isDisabled ? "cursor-not-allowed" : ""
          )}
        />
      </div>
      <div className="text-sm leading-6">
        <label htmlFor={id} className="font-medium text-gray-900">
          <FormattedMessage id={id} />
        </label>
      </div>
    </div>
  );
}
