import { FormattedMessage } from "react-intl";
import { HalfWidthPageWrapper } from "../../components/page-wrappers/PageWrappers";
import { joinClasses } from "../../helpers/Utils";

const items = [
  {
    title: null,
    sections: [
      {
        title: null,
        content: [{ type: "paragraph", key: "tos.intro" }],
      },
    ],
  },
  {
    title: "tos.scope",
    sections: [
      {
        title: null,
        content: [
          { type: "paragraph", key: "tos.scope.p1" },
          { type: "paragraph", key: "tos.scope.p2" },
          { type: "paragraph", key: "tos.scope.p3" },
          { type: "paragraph", key: "tos.scope.p4" },
          { type: "paragraph", key: "tos.scope.p5" },
        ],
      },
    ],
  },
  {
    title: "tos.services",
    sections: [
      {
        title: "tos.services.right.of.use",
        content: [
          { type: "paragraph", key: "tos.services.right.of.use.p1" },
          { type: "paragraph", key: "tos.services.right.of.use.p2" },
          { type: "paragraph", key: "tos.services.right.of.use.p3" },
        ],
      },
      {
        title: "tos.services.restriction",
        content: [
          { type: "paragraph", key: "tos.services.restriction.p1" },
          {
            type: "list",
            keys: [
              "tos.services.restriction.l1",
              "tos.services.restriction.l2",
              "tos.services.restriction.l3",
              "tos.services.restriction.l4",
              "tos.services.restriction.l5",
            ],
          },
        ],
      },
      {
        title: "tos.services.operation",
        content: [
          { type: "paragraph", key: "tos.services.operation.p1" },
          { type: "paragraph", key: "tos.services.operation.p2" },
        ],
      },
      {
        title: "tos.services.support",
        content: [
          { type: "paragraph", key: "tos.services.support.p1" },
          {
            type: "paragraph",
            key: "tos.services.support.p2",
            values: {
              link: (
                <a href="mailto:support@yumalarm.com">support@yumalarm.com</a>
              ),
            },
          },
        ],
      },
      {
        title: "tos.services.changes",
        content: [
          { type: "paragraph", key: "tos.services.changes.p1" },
          {
            type: "list",
            keys: [
              "tos.services.changes.l1",
              "tos.services.changes.l2",
              "tos.services.changes.l3",
              "tos.services.changes.l4",
            ],
          },
        ],
      },
    ],
  },
  {
    title: "tos.involvement",
    sections: [
      {
        title: null,
        content: [
          { type: "paragraph", key: "tos.involvement.p1" },
          { type: "paragraph", key: "tos.involvement.p2" },
        ],
      },
    ],
  },
  {
    title: "tos.renumeration",
    sections: [
      {
        title: "tos.renumeration.fee.structure",
        content: [
          { type: "paragraph", key: "tos.renumeration.fee.structure.p1" },
        ],
      },
      {
        title: "tos.renumeration.payment",
        content: [
          { type: "paragraph", key: "tos.renumeration.payment.p1" },
          { type: "paragraph", key: "tos.renumeration.payment.p2" },
        ],
      },
      {
        title: "tos.renumeration.prices",
        content: [{ type: "paragraph", key: "tos.renumeration.prices.p1" }],
      },
      {
        title: "tos.renumeration.measures",
        content: [{ type: "paragraph", key: "tos.renumeration.measures.p1" }],
      },
    ],
  },
  {
    title: "tos.data",
    sections: [
      {
        title: "tos.data.submission.of.content",
        content: [
          {
            type: "paragraph",
            key: "tos.data.submission.of.content.p1",
          },
        ],
      },
      {
        title: "tos.data.prohibited.content",
        content: [{ type: "paragraph", key: "tos.data.prohibited.content.p1" }],
      },
      {
        title: "tos.data.rights",
        content: [
          {
            type: "paragraph",
            key: "tos.data.rights.p1",
          },
          {
            type: "paragraph",
            key: "tos.data.rights.p2",
          },
          {
            type: "paragraph",
            key: "tos.data.rights.p3",
          },
          {
            type: "paragraph",
            key: "tos.data.rights.p4",
          },
        ],
      },
      {
        title: "tos.data.protection",
        content: [
          { type: "paragraph", key: "tos.data.protection.p1" },
          { type: "paragraph", key: "tos.data.protection.p2" },
          { type: "paragraph", key: "tos.data.protection.p3" },
        ],
      },
      {
        title: "tos.data.retention",
        content: [{ type: "paragraph", key: "tos.data.retention.p1" }],
      },
      {
        title: "tos.data.deletion",
        content: [{ type: "paragraph", key: "tos.data.deletion.p1" }],
      },
    ],
  },
  {
    title: "tos.release",
    sections: [
      {
        title: null,
        content: [
          { type: "paragraph", key: "tos.release.p1" },
          { type: "paragraph", key: "tos.release.p2" },
          { type: "paragraph", key: "tos.release.p3" },
        ],
      },
    ],
  },
  {
    title: "tos.liability",
    sections: [
      {
        title: null,
        content: [
          { type: "paragraph", key: "tos.liability.p1" },
          {
            type: "list",
            keys: [
              "tos.liability.l1",
              "tos.liability.l2",
              "tos.liability.l3",
              "tos.liability.l4",
              "tos.liability.l5",
              "tos.liability.l6",
              "tos.liability.l7",
              "tos.liability.l8",
              "tos.liability.l9",
              "tos.liability.l10",
            ],
          },
        ],
      },
    ],
  },
  {
    title: "tos.warranties",
    sections: [
      {
        title: null,
        content: [{ type: "paragraph", key: "tos.warranties.p1" }],
      },
    ],
  },
  {
    title: "tos.term.and.termination",
    sections: [
      {
        title: "tos.term.and.termination.term",
        content: [
          { type: "paragraph", key: "tos.term.and.termination.term.p1" },
          { type: "paragraph", key: "tos.term.and.termination.term.p2" },
        ],
      },
      {
        title: "tos.term.and.termination.termination",
        content: [
          { type: "paragraph", key: "tos.term.and.termination.termination.p1" },
          { type: "paragraph", key: "tos.term.and.termination.termination.p2" },
          {
            type: "list",
            keys: [
              "tos.term.and.termination.termination.l1",
              "tos.term.and.termination.termination.l2",
              "tos.term.and.termination.termination.l3",
            ],
          },
        ],
      },
      {
        title: "tos.term.and.termination.data.after.termination",
        content: [
          {
            type: "paragraph",
            key: "tos.term.and.termination.data.after.termination.p1",
          },
          {
            type: "paragraph",
            key: "tos.term.and.termination.data.after.termination.p2",
          },
          {
            type: "paragraph",
            key: "tos.term.and.termination.data.after.termination.p3",
          },
        ],
      },
    ],
  },
  {
    title: "tos.confidentiality",
    sections: [
      {
        title: null,
        content: [
          { type: "paragraph", key: "tos.confidentiality.p1" },
          { type: "paragraph", key: "tos.confidentiality.p2" },
        ],
      },
    ],
  },
  {
    title: "tos.final.provisions",
    sections: [
      {
        title: "tos.final.provisions.ammendments",
        content: [
          { type: "paragraph", key: "tos.final.provisions.ammendments.p1" },
        ],
      },
      {
        title: "tos.final.provisions.offsetting",
        content: [
          { type: "paragraph", key: "tos.final.provisions.offsetting.p1" },
          { type: "paragraph", key: "tos.final.provisions.offsetting.p2" },
        ],
      },
      {
        title: "tos.final.provisions.place",
        content: [{ type: "paragraph", key: "tos.final.provisions.place.p1" }],
      },
      {
        title: "tos.final.provisions.severability",
        content: [
          { type: "paragraph", key: "tos.final.provisions.severability.p1" },
        ],
      },
      {
        title: "tos.final.provisions.contract.conclusion",
        content: [
          {
            type: "paragraph",
            key: "tos.final.provisions.contract.conclusion.p1",
          },
        ],
      },
    ],
  },
  {
    title: "tos.applicable.law",
    sections: [
      {
        title: null,
        content: [
          { type: "paragraph", key: "tos.applicable.law.p1" },
          { type: "paragraph", key: "tos.applicable.law.p2" },
        ],
      },
    ],
  },
];

function renderItem(item) {
  return (
    <>
      {item.title && (
        <h2 className="mt-6 text-2xl font-bold tracking-tight text-gray-900">
          <FormattedMessage id={item.title} />
        </h2>
      )}
      {item.sections.map((section) => {
        return renderSection(section);
      })}
    </>
  );
}

function renderSection(section) {
  return (
    <>
      {section.title && (
        <h3 className="mt-6 text-xl font-bold tracking-tight text-gray-900">
          <FormattedMessage id={section.title} />
        </h3>
      )}
      {section.content.map((content, idx) => {
        return renderContent(content, idx);
      })}
    </>
  );
}

function renderContent(content, idx) {
  if (content.type === "list") {
    return (
      <ul
        className={joinClasses(
          "mt-2 space-y-4 text-gray-600 ml-4",
          content.noDots ? "" : "list-disc"
        )}
      >
        {content.keys.map((listKey) => {
          return (
            <li className="gap-x-3">
              <span>
                <FormattedMessage id={listKey} />
              </span>
            </li>
          );
        })}
      </ul>
    );
  } else {
    if (idx === 0) {
      return (
        <p className="mt-6 leading-8">
          <FormattedMessage id={content.key} values={content.values} />
        </p>
      );
    } else {
      return (
        <p className="mt-2 leading-8">
          <FormattedMessage id={content.key} values={content.values} />
        </p>
      );
    }
  }
}

export default function TermsOfServicePage() {
  return (
    <HalfWidthPageWrapper>
      <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
        <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          <FormattedMessage id="tos" />
        </h1>
        <p className="text-base font-semibold leading-7 text-gray-700">
          <FormattedMessage id="tos.version" />
        </p>
        {items.map((item) => {
          return renderItem(item);
        })}
      </div>
    </HalfWidthPageWrapper>
  );
}
