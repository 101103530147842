import { FormattedMessage } from "react-intl";

export default function GeneralError({ error, resetErrorBoundary }) {
  return (
    <main className="bg-white shadow flex-1">
      <div className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            <FormattedMessage id="general.error.title" />
          </h1>
          <p className="mt-6 text-base leading-7 text-gray-600">
            <FormattedMessage id="general.error.description" />
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <a
              href="/"
              onClick={resetErrorBoundary}
              className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              <FormattedMessage id="general.error.try.again" />
            </a>
            <a
              href="mailto:support@yumalarm.com"
              className="text-sm font-semibold text-gray-900"
            >
              <FormattedMessage id="contact.support" />
              <span aria-hidden="true">&rarr;</span>
            </a>
          </div>
        </div>
      </div>
    </main>
  );
}
