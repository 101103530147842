import { useIntl } from "react-intl";
import { HalfWidthPageWrapper } from "../../components/page-wrappers/PageWrappers";
import useRestApi from "../../custom-hooks/useRestApi";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import List from "../../components/list/List";
import { useState } from "react";
import Loading from "../../components/loading/Loading";

export default function ListInvitesPage() {
  const [loading, setLoading] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const intl = useIntl();
  const { executeGetCall, executeDeleteCall, executePutCall } = useRestApi();

  async function handleFetchItems(nextToken) {
    let queryParams = { limit: 10 };
    if (nextToken) {
      queryParams.token = JSON.stringify(nextToken);
    }

    let { body } = await executeGetCall("permissions/me", { queryParams });

    return {
      items: body.items,
      token: body.nextToken ? body.nextToken : null,
    };
  }

  async function declineInvite(inviteId) {
    if (!inviteId) {
      return;
    }
    setLoading(true);
    await executeDeleteCall("permissions/" + inviteId);
    setRefreshKey((prev) => prev + 1);
    setLoading(false);
  }

  async function acceptInvite(inviteId) {
    if (!inviteId) {
      return;
    }
    setLoading(true);
    await executePutCall(`permissions/${inviteId}/accept`);
    setRefreshKey((prev) => prev + 1);
    setLoading(false);
  }

  function labels(invite) {
    return [
      {
        value: invite.categoryName,
      },
      {
        value: invite.status,
        classes:
          invite.status === "Pending" ? "text-yellow-600" : "text-green-600",
      },
    ];
  }

  function actions(invite) {
    if (invite.status === "Pending") {
      return [
        {
          element: (
            <Link onClick={() => acceptInvite(invite.id)}>
              <FormattedMessage id="Accept" />
            </Link>
          ),
          textClasses: "text-green-600 hover:text-green-900",
        },
        {
          element: (
            <Link onClick={() => declineInvite(invite.id)}>
              <FormattedMessage id="Decline" />
            </Link>
          ),
          textClasses: "text-red-600 hover:text-red-900",
        },
      ];
    }

    return [];
  }

  return (
    <HalfWidthPageWrapper>
      {loading && <Loading />}
      {!loading && (
        <List
          title={intl.formatMessage({ id: "invites.mine" })}
          emptyListTitle={intl.formatMessage({
            id: "empty.list.invites.mine.title",
          })}
          emptyListDescription={intl.formatMessage({
            id: "empty.list.invites.mine.description",
          })}
          fetchItemsMethod={handleFetchItems}
          actions={actions}
          labels={labels}
          refreshKey={refreshKey}
          showAddButton={false}
        />
      )}
    </HalfWidthPageWrapper>
  );
}
