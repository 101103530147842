import { FormattedMessage } from "react-intl";

export default function PageTitle({ titleKey }) {
  return (
    <div className="w-full flex flex-col items-center mb-6 sm:mb-8">
      <h1 className="text-3xl font-bold tracking-tight text-gray-900">
        <FormattedMessage id={titleKey} />
      </h1>
    </div>
  );
}
