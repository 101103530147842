import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import Loading from "../../components/loading/Loading";
import { getInitialMeal } from "../../helpers/Utils";
import TextInput from "../../components/input/text/TextInput";
import TextAreaInput from "../../components/input/text/TextAreaInput";
import { useLocation } from "react-router-dom";
import { HalfWidthPageWrapper } from "../../components/page-wrappers/PageWrappers";
import FormTitle from "../../components/form-title/FormTitle";
import useRestApi from "../../custom-hooks/useRestApi";

export default function ViewMealPage() {
  const { mealId } = useParams();
  const [meal, setMeal] = useState(getInitialMeal());
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const { executeGetCall } = useRestApi();
  const comingFromAddPage = location.state?.comingFromAddPage;

  useEffect(() => {
    async function handleFetchItems(mealId) {
      let { body } = await executeGetCall(`meals/${mealId}`);
      setMeal(body);
      setLoading(false);
    }

    handleFetchItems(mealId);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mealId]);

  const renderForm = () => {
    return (
      <form>
        <div className="space-y-10 sm:space-y-12">
          <FormTitle titleKey="meal.view" />

          <div className="mt-10 space-y-8 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-300 sm:pb-0">
            <TextInput
              labelTextKey="meal.name"
              id="meal-name"
              value={meal.name}
              disabled={true}
            />

            <TextInput
              labelTextKey="category.name"
              id="category-name"
              value={meal.category.name}
              disabled={true}
            />

            <TextAreaInput
              labelTextKey="Description"
              id="meal-description"
              value={meal.description}
              disabled={true}
            />
          </div>
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6">
          <Link
            to={"/meals/edit/" + meal.sharedId}
            className="rounded-md bg-indigo-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <FormattedMessage id="Edit" />
          </Link>
          {comingFromAddPage && (
            <Link
              to={"/meals/add"}
              className="block rounded-md bg-indigo-600 py-2 px-3 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              <FormattedMessage id="add.new" />
            </Link>
          )}
        </div>
      </form>
    );
  };

  return (
    <HalfWidthPageWrapper>
      {loading && <Loading />}
      {!loading && renderForm()}
    </HalfWidthPageWrapper>
  );
}
