import { FormattedMessage } from "react-intl";
import { HalfWidthPageWrapper } from "../../components/page-wrappers/PageWrappers";

export default function SuccessPage({ detailMessageKey }) {
  return (
    <HalfWidthPageWrapper>
      <div className="text-center">
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          <FormattedMessage id="success.banner" />
        </h1>
        <p className="mt-6 text-base leading-7 text-gray-600">
          <FormattedMessage id={detailMessageKey} />
        </p>
      </div>
    </HalfWidthPageWrapper>
  );
}
