import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/home/HomePage";
import GenerateMealPage from "./pages/generate/GenerateMealPage";
import NotFoundPage from "./pages/not-found/NotFoundPage";
import SubscribePage from "./pages/subscribe/SubscribePage";
import ViewSettingsPage from "./pages/settings/ViewSettingsPage";
import EditEmailPage from "./pages/settings/EditEmailPage";
import EditPasswordPage from "./pages/settings/EditPasswordPage";
import LoginPage from "./pages/login/LoginPage";
import ListMealsPage from "./pages/meals/ListMealsPage";
import AddMealPage from "./pages/meals/AddMealPage";
import EditMealPage from "./pages/meals/EditMealPage";
import ViewMealPage from "./pages/meals/ViewMealPage";
import ListCategoriesPage from "./pages/categories/ListCategoriesPage";
import AddCategoryPage from "./pages/categories/AddCategoryPage";
import EditCategoryPage from "./pages/categories/EditCategoryPage";
import ShareCategoryPage from "./pages/categories/ShareCategoryPage";
import ViewCategoryPage from "./pages/categories/ViewCategoryPage";
import ListNotificationsPage from "./pages/notifications/ListNotificationsPage";
import AddNotificationPage from "./pages/notifications/AddNotificationPage";
import EditNotificationPage from "./pages/notifications/EditNotificationPage";
import ViewNotificationPage from "./pages/notifications/ViewNotificationPage";
import RequireAuthWrapper from "./wrappers/auth/RequireAuthWrapper";
import UsagePage from "./pages/usage/UsagePage";
import FAQPage from "./pages/faq/FAQPage";
import TermsOfServicePage from "./pages/tos/TermsOfServicePage";
import PrivacyPolicyPage from "./pages/privacy/PrivacyPolicyPage";
import ReferralsPage from "./pages/referrals/ReferralsPage";
import SuccessPage from "./pages/success/SuccessPage";
import ListInvitesPage from "./pages/invites/ListInvitesPage";
import InternalPage from "./components/internal-page/InternalPage";
import LandingPage from "./pages/landing/LandingPage";
import { useContext } from "react";
import { UserContext } from "./wrappers/user/UserDataWrapper";

export default function YumAlarmRoutes() {
  const { isLoggedIn } = useContext(UserContext);

  function getHomePage() {
    if (isLoggedIn) {
      return (
        <RequireAuthWrapper>
          <InternalPage>
            <HomePage />
          </InternalPage>
        </RequireAuthWrapper>
      );
    } else {
      return <LandingPage />;
    }
  }

  return (
    <Routes>
      <Route path="/" element={getHomePage()} />
      <Route
        path="generate"
        element={
          <RequireAuthWrapper>
            <InternalPage>
              <GenerateMealPage />
            </InternalPage>
          </RequireAuthWrapper>
        }
      />
      <Route
        path="meals"
        element={
          <RequireAuthWrapper>
            <InternalPage>
              <ListMealsPage />
            </InternalPage>
          </RequireAuthWrapper>
        }
      />
      <Route
        path="meals/add"
        element={
          <RequireAuthWrapper>
            <InternalPage>
              <AddMealPage />
            </InternalPage>
          </RequireAuthWrapper>
        }
      />
      <Route
        path="meals/view/:mealId"
        element={
          <RequireAuthWrapper>
            <InternalPage>
              <ViewMealPage />
            </InternalPage>
          </RequireAuthWrapper>
        }
      />
      <Route
        path="meals/edit/:mealId"
        element={
          <RequireAuthWrapper>
            <InternalPage>
              <EditMealPage />
            </InternalPage>
          </RequireAuthWrapper>
        }
      />
      <Route
        path="categories"
        element={
          <RequireAuthWrapper>
            <InternalPage>
              <ListCategoriesPage />
            </InternalPage>
          </RequireAuthWrapper>
        }
      />
      <Route
        path="categories/add"
        element={
          <RequireAuthWrapper>
            <InternalPage>
              <AddCategoryPage />
            </InternalPage>
          </RequireAuthWrapper>
        }
      />
      <Route
        path="categories/view/:categoryId"
        element={
          <RequireAuthWrapper>
            <InternalPage>
              <ViewCategoryPage />
            </InternalPage>
          </RequireAuthWrapper>
        }
      />
      <Route
        path="categories/edit/:categoryId"
        element={
          <RequireAuthWrapper>
            <InternalPage>
              <EditCategoryPage />
            </InternalPage>
          </RequireAuthWrapper>
        }
      />
      <Route
        path="categories/share/:categoryId"
        element={
          <RequireAuthWrapper>
            <InternalPage>
              <ShareCategoryPage />
            </InternalPage>
          </RequireAuthWrapper>
        }
      />
      <Route
        path="share-invites"
        element={
          <RequireAuthWrapper>
            <InternalPage>
              <ListInvitesPage />
            </InternalPage>
          </RequireAuthWrapper>
        }
      />
      <Route
        path="notifications"
        element={
          <RequireAuthWrapper>
            <InternalPage>
              <ListNotificationsPage />
            </InternalPage>
          </RequireAuthWrapper>
        }
      />
      <Route
        path="notifications/add"
        element={
          <RequireAuthWrapper>
            <InternalPage>
              <AddNotificationPage />
            </InternalPage>
          </RequireAuthWrapper>
        }
      />
      <Route
        path="notifications/view/:notificationId"
        element={
          <RequireAuthWrapper>
            <InternalPage>
              <ViewNotificationPage />
            </InternalPage>
          </RequireAuthWrapper>
        }
      />
      <Route
        path="notifications/edit/:notificationId"
        element={
          <RequireAuthWrapper>
            <InternalPage>
              <EditNotificationPage />
            </InternalPage>
          </RequireAuthWrapper>
        }
      />
      <Route
        path="subscribe"
        element={
          <InternalPage>
            <SubscribePage />
          </InternalPage>
        }
      />
      <Route
        path="settings"
        element={
          <RequireAuthWrapper>
            <InternalPage>
              <ViewSettingsPage />
            </InternalPage>
          </RequireAuthWrapper>
        }
      />
      <Route
        path="settings/edit/email"
        element={
          <RequireAuthWrapper>
            <InternalPage>
              <EditEmailPage />
            </InternalPage>
          </RequireAuthWrapper>
        }
      />
      <Route
        path="settings/edit/password"
        element={
          <RequireAuthWrapper>
            <InternalPage>
              <EditPasswordPage />
            </InternalPage>
          </RequireAuthWrapper>
        }
      />
      <Route
        path="usage"
        element={
          <RequireAuthWrapper>
            <InternalPage>
              <UsagePage />
            </InternalPage>
          </RequireAuthWrapper>
        }
      />
      <Route
        path="referrals"
        element={
          <RequireAuthWrapper>
            <InternalPage>
              <ReferralsPage />
            </InternalPage>
          </RequireAuthWrapper>
        }
      />
      <Route
        path="login"
        element={
          <InternalPage>
            <LoginPage />
          </InternalPage>
        }
      />
      <Route
        path="frequently-asked-questions"
        element={
          <InternalPage>
            <FAQPage />
          </InternalPage>
        }
      />
      <Route
        path="terms-of-service"
        element={
          <InternalPage>
            <TermsOfServicePage />
          </InternalPage>
        }
      />
      <Route
        path="privacy-policy"
        element={
          <InternalPage>
            <PrivacyPolicyPage />
          </InternalPage>
        }
      />
      <Route
        path="signup-success"
        element={
          <InternalPage>
            <SuccessPage detailMessageKey="success.signup" />
          </InternalPage>
        }
      />
      <Route
        path="update-success"
        element={
          <InternalPage>
            <SuccessPage detailMessageKey="success.update" />
          </InternalPage>
        }
      />
      <Route
        path="*"
        element={
          <InternalPage>
            <NotFoundPage />
          </InternalPage>
        }
      />
    </Routes>
  );
}
