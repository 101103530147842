import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useIntl, FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import CategoryAutocomplete from "../../components/autocomplete/CategoryAutocomplete";
import { validateMeal } from "../../validation/MealValidation";
import Loading from "../../components/loading/Loading";
import { getInitialMeal } from "../../helpers/Utils";
import TextInput from "../../components/input/text/TextInput";
import TextAreaInput from "../../components/input/text/TextAreaInput";
import { HalfWidthPageWrapper } from "../../components/page-wrappers/PageWrappers";
import FormTitle from "../../components/form-title/FormTitle";
import useRestApi from "../../custom-hooks/useRestApi";

export default function EditMealPage() {
  const { mealId } = useParams();
  const [meal, setMeal] = useState(getInitialMeal());
  const [categories, setCategories] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { executeGetCall, executePutCall } = useRestApi();
  const intl = useIntl();

  useEffect(() => {
    async function loadMeal() {
      let { body } = await executeGetCall(`meals/${mealId}`);
      setMeal(body);
      setLoading(false);
    }

    loadMeal();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mealId]);

  useEffect(() => {
    async function initialLoad() {
      let { body } = await executeGetCall("categories");
      setCategories(body);
    }

    initialLoad();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleSaveClick() {
    const validationResult = validateMeal(meal, intl);

    if (!validationResult.isValid) {
      setValidationErrors(validationResult.validationErrors);
      return;
    }

    await executePutCall(`meals/${mealId}`, {
      body: {
        id: meal.id,
        name: meal.name,
        description: meal.description,
        categoryId: meal.category.id,
        sharedCategoryId: meal.category.sharedId,
        isShared: meal.isShared,
        sharedId: meal.sharedId,
      },
    });
    navigate(`/meals/view/${meal.sharedId}`);
  }

  const renderForm = () => {
    return (
      <form>
        <div className="space-y-10 sm:space-y-12">
          <FormTitle titleKey="meal.edit" />

          <div className="mt-10 space-y-8 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-300 sm:pb-0">
            <TextInput
              labelTextKey="meal.name"
              id="meal-name"
              validationError={validationErrors["name"]}
              value={meal.name}
              handleOnChange={(event) => {
                setValidationErrors({ ...validationErrors, name: null });
                setMeal({ ...meal, name: event.target.value });
              }}
            />

            <CategoryAutocomplete
              categories={categories}
              onCategorySelected={(selectedCategory) => {
                setValidationErrors({
                  ...validationErrors,
                  category: null,
                });
                setMeal({ ...meal, category: selectedCategory });
              }}
              validationError={validationErrors["category"]}
              selectedCategory={meal.category}
            />

            <TextAreaInput
              labelTextKey="Description"
              id="meal-description"
              value={meal.description}
              handleOnChange={(event) =>
                setMeal({
                  ...meal,
                  description: event.target.value,
                })
              }
            />
          </div>
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6">
          <Link
            to="/meals"
            className="text-sm font-semibold leading-6 text-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <FormattedMessage id="Cancel" />
          </Link>

          <button
            type="button"
            className="rounded-md bg-indigo-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={handleSaveClick}
          >
            <FormattedMessage id="Save" />
          </button>
        </div>
      </form>
    );
  };

  return (
    <HalfWidthPageWrapper>
      {loading && <Loading />}
      {!loading && renderForm()}
    </HalfWidthPageWrapper>
  );
}
