import React from "react";
import LanguageWrapper from "./wrappers/language/LanguageWrapper";
import App from "./App";
import "./index.css";
import { Amplify } from "aws-amplify";
import output from "./output";
import { BrowserRouter } from "react-router-dom";
import { Authenticator } from "@aws-amplify/ui-react";
import { ErrorBoundary } from "react-error-boundary";
import GeneralError from "./components/error/GeneralError";
import TourWrapper from "./wrappers/tour/TourWrapper";
import UserDataWrapper from "./wrappers/user/UserDataWrapper";
import { createRoot } from "react-dom/client";

const stackProperties = Object.values(output)[0];

const amplifyConfig = {
  Auth: {
    Cognito: {
      userPoolClientId: stackProperties.cognitoUserPoolWebClientID,
      userPoolId: stackProperties.cognitoUserPoolID,
    },
  },
  API: {
    GraphQL: {
      endpoint: stackProperties.appsyncGraphqlURL,
      defaultAuthMode: stackProperties.appsyncAuthenticationType,
      region: stackProperties.region,
    },
    REST: {
      YumalarmRestAPI: {
        endpoint: stackProperties.restApiURL,
        region: stackProperties.region,
      },
    },
  },
};

Amplify.configure(amplifyConfig);

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <Authenticator.Provider>
      <LanguageWrapper>
        <TourWrapper>
          <UserDataWrapper>
            <BrowserRouter>
              <ErrorBoundary FallbackComponent={GeneralError}>
                <App />
              </ErrorBoundary>
            </BrowserRouter>
          </UserDataWrapper>
        </TourWrapper>
      </LanguageWrapper>
    </Authenticator.Provider>
  </React.StrictMode>
);
