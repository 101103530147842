import { FormattedMessage } from "react-intl";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";

export default function LimitReached({ infoMessageKey, linkMessageKey }) {
  return (
    <div className="mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8">
      <div className="w-full flex flex-col items-center lg:my-4">
        <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationTriangleIcon
                className="h-5 w-5 text-yellow-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <p className="text-sm text-yellow-700">
                <FormattedMessage id={infoMessageKey} />
                <Link
                  to={process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK}
                  className="font-medium text-yellow-700 underline hover:text-yellow-600"
                >
                  <FormattedMessage id={linkMessageKey} />
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
