import { useLocation, Navigate } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../user/UserDataWrapper";
import Loading from "../../components/loading/Loading";

export default function RequireAuthWrapper({ children }) {
  const location = useLocation();
  const { loading, isLoggedIn, hasValidSubscription } = useContext(UserContext);

  if (loading) {
    return <Loading />;
  }

  if (isLoggedIn) {
    if (hasValidSubscription) {
      return children;
    } else {
      return <Navigate to="/subscribe" state={{ from: location }} replace />;
    }
  } else {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
}
