import { CUSTOM_ATTRIBUTES, SUBSCRIPTION_PLAN } from "../constants/Constants";

export function isUserSubscribed(userAttributes) {
  return (
    userAttributes &&
    userAttributes[CUSTOM_ATTRIBUTES.SUBSCRIBED] &&
    userAttributes[CUSTOM_ATTRIBUTES.SUBSCRIBED] === "true"
  );
}

export function getUserMealsLimit(userAttributes) {
  if (!userAttributes || !userAttributes[CUSTOM_ATTRIBUTES.SUBSCRIPTION_PLAN]) {
    return 0;
  }

  switch (userAttributes[CUSTOM_ATTRIBUTES.SUBSCRIPTION_PLAN]) {
    case SUBSCRIPTION_PLAN.BASIC.value:
      return SUBSCRIPTION_PLAN.BASIC.MEAL_LIMIT;
    case SUBSCRIPTION_PLAN.STANDARD.value:
      return SUBSCRIPTION_PLAN.STANDARD.MEAL_LIMIT;
    case SUBSCRIPTION_PLAN.PRO.value:
      return SUBSCRIPTION_PLAN.PRO.MEAL_LIMIT;
    default:
      return 0;
  }
}

export function getUserNotificationsLimit(userAttributes) {
  if (!userAttributes || !userAttributes[CUSTOM_ATTRIBUTES.SUBSCRIPTION_PLAN]) {
    return 0;
  }

  switch (userAttributes[CUSTOM_ATTRIBUTES.SUBSCRIPTION_PLAN]) {
    case SUBSCRIPTION_PLAN.BASIC.value:
      return SUBSCRIPTION_PLAN.BASIC.NOTIFICAITON_LIMIT;
    case SUBSCRIPTION_PLAN.STANDARD.value:
      return SUBSCRIPTION_PLAN.STANDARD.NOTIFICAITON_LIMIT;
    case SUBSCRIPTION_PLAN.PRO.value:
      return SUBSCRIPTION_PLAN.PRO.NOTIFICAITON_LIMIT;
    default:
      return 0;
  }
}

export function getUserCategoriesLimit(userAttributes) {
  if (!userAttributes || !userAttributes[CUSTOM_ATTRIBUTES.SUBSCRIPTION_PLAN]) {
    return 0;
  }

  switch (userAttributes[CUSTOM_ATTRIBUTES.SUBSCRIPTION_PLAN]) {
    case SUBSCRIPTION_PLAN.BASIC.value:
      return SUBSCRIPTION_PLAN.BASIC.CATEGORY_LIMIT;
    case SUBSCRIPTION_PLAN.STANDARD.value:
      return SUBSCRIPTION_PLAN.STANDARD.CATEGORY_LIMIT;
    case SUBSCRIPTION_PLAN.PRO.value:
      return SUBSCRIPTION_PLAN.PRO.CATEGORY_LIMIT;
    default:
      return 0;
  }
}

export function getReferralCount(userAttributes) {
  if (!userAttributes || !userAttributes[CUSTOM_ATTRIBUTES.REFERRAL_COUNT]) {
    return 0;
  } else {
    return userAttributes[CUSTOM_ATTRIBUTES.REFERRAL_COUNT];
  }
}

export function getReferralCode(userAttributes) {
  if (!userAttributes || !userAttributes[CUSTOM_ATTRIBUTES.REFERRAL_CODE]) {
    return "";
  } else {
    return userAttributes[CUSTOM_ATTRIBUTES.REFERRAL_CODE];
  }
}
