import "react-toastify/dist/ReactToastify.css";
import YumAlarmRoutes from "./YumAlarmRoutes";
import { useContext } from "react";
import { UserContext } from "./wrappers/user/UserDataWrapper";
import Loading from "./components/loading/Loading";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

export default function App() {
  const { loading } = useContext(UserContext);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <YumAlarmRoutes />
      <ToastContainer />
    </>
  );
}
