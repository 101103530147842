import { FormattedMessage } from "react-intl";
import { Disclosure } from "@headlessui/react";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/24/outline";

const faqs = [
  { key: "faq.free.trial" },
  { key: "faq.pricing.model" },
  { key: "faq.minimum.duration" },
  { key: "faq.cancel.subscription" },
  { key: "faq.switch.plans" },
  { key: "faq.payment.options" },
  { key: "faq.process.payments" },
  {
    key: "faq.customer.support",
    values: {
      link: <a href="mailto:support@yumalarm.com">support@yumalarm.com</a>,
    },
  },
  { key: "faq.discount" },
  { key: "faq.notification.time" },
];

export default function FAQ() {
  return (
    <div className="mx-auto max-w-7xl px-6 py-14">
      <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
        <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
          <FormattedMessage id="faq" />
        </h2>
        <p className="mt-6 text-base leading-7 text-gray-600">
          <FormattedMessage id="faq.cant.find.question" />
          <br />
          <FormattedMessage id="faq.reach.out.part1" />
          <a
            href="mailto:support@yumalarm.com"
            className="font-semibold text-indigo-600 hover:text-indigo-500"
          >
            <FormattedMessage id="faq.reach.out.part2" />
          </a>
          <FormattedMessage id="faq.reach.out.part3" />
        </p>
        <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
          {faqs.map((faq) => (
            <Disclosure as="div" key={faq.key} className="pt-6">
              {({ open }) => (
                <>
                  <dt>
                    <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                      <span className="text-base font-semibold leading-7">
                        <FormattedMessage id={faq.key + ".question"} />
                      </span>
                      <span className="ml-6 flex h-7 items-center">
                        {open ? (
                          <MinusSmallIcon
                            className="h-6 w-6"
                            aria-hidden="true"
                          />
                        ) : (
                          <PlusSmallIcon
                            className="h-6 w-6"
                            aria-hidden="true"
                          />
                        )}
                      </span>
                    </Disclosure.Button>
                  </dt>
                  <Disclosure.Panel as="dd" className="mt-2 pr-12">
                    <p className="text-base leading-7 text-gray-600">
                      <FormattedMessage
                        id={faq.key + ".answer"}
                        values={faq.values}
                      />
                    </p>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          ))}
        </dl>
      </div>
    </div>
  );
}
