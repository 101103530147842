import { useState, useEffect } from "react";
import CategoryAutocomplete from "../../components/autocomplete/CategoryAutocomplete";
import { HalfWidthPageWrapper } from "../../components/page-wrappers/PageWrappers";
import { useIntl, FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import PageTitle from "../../components/page-title/PageTitle";
import useRestApi from "../../custom-hooks/useRestApi";

export default function GenerateMealPage() {
  const [generatedMeal, setGeneratedMeal] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categories, setCategories] = useState([]);
  const [validationError, setValidationError] = useState("");
  const { executeGetCall } = useRestApi();
  const intl = useIntl();

  useEffect(() => {
    async function initialLoad() {
      let { body } = await executeGetCall("categories");
      setCategories(body);
    }

    initialLoad();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleGenerateClick() {
    if (!selectedCategory) {
      setValidationError(
        intl.formatMessage({
          id: "generate.meal.validation.category.empty",
        })
      );
      return;
    }

    let { statusCode, body } = await executeGetCall("meals/random", {
      queryParams: {
        categoryId: selectedCategory.sharedId,
      },
    });

    if (statusCode === 204) {
      setValidationError(
        intl.formatMessage({
          id: "generate.meal.validation.category.meals.empty",
        })
      );
      return;
    }

    setGeneratedMeal(body);
  }

  return (
    <HalfWidthPageWrapper>
      <PageTitle titleKey="random.meal" />

      <div className="col-span-full">
        <CategoryAutocomplete
          categories={categories}
          onCategorySelected={(selectedCategory) => {
            setValidationError("");
            setSelectedCategory(selectedCategory);
            setGeneratedMeal(null);
          }}
          validationError={validationError}
          selectedCategory={selectedCategory}
        />
      </div>
      <div className="col-span-full flex justify-center items-center mt-6">
        <button
          type="button"
          className="rounded-md bg-indigo-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={handleGenerateClick}
        >
          <FormattedMessage id="generate.meal" />
        </button>
      </div>
      {generatedMeal && (
        <div className="w-full h-full flex justify-center">
          <Link
            to={"/meals/view/" + generatedMeal.id}
            className="mt-8 lg:mt-24 w-3/4 lg:w-1/3 flex justify-center text-xl lg:text-3xl font-bold lg:font-extrabold rounded-lg px-2 py-4 lg:px-4 lg:py-8 shadow-indigo-300 shadow-xl text-indigo-500 outline-none ring-4 ring-indigo-500"
          >
            {generatedMeal.name}
          </Link>
        </div>
      )}
    </HalfWidthPageWrapper>
  );
}
