import {
  Cog6ToothIcon,
  EnvelopeOpenIcon,
  PlusIcon,
  QueueListIcon,
  ChartBarSquareIcon,
} from "@heroicons/react/24/outline";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { joinClasses } from "../../helpers/Utils";

const actions = [
  {
    titleId: "landing.action.generate",
    descriptionId: "landing.action.generate.description",
    href: "/generate",
    icon: PlusIcon,
    iconForeground: "text-teal-700",
    iconBackground: "bg-teal-50",
  },
  {
    titleId: "landing.action.meals",
    descriptionId: "landing.action.meals.description",
    href: "/meals",
    icon: QueueListIcon,
    iconForeground: "text-purple-700",
    iconBackground: "bg-purple-50",
  },
  {
    titleId: "landing.action.categories",
    descriptionId: "landing.action.categories.description",
    href: "/categories",
    icon: QueueListIcon,
    iconForeground: "text-sky-700",
    iconBackground: "bg-sky-50",
  },
  {
    titleId: "landing.action.notifications",
    descriptionId: "landing.action.notifications.description",
    href: "/notifications",
    icon: EnvelopeOpenIcon,
    iconForeground: "text-yellow-700",
    iconBackground: "bg-yellow-50",
  },
  {
    titleId: "landing.action.settings",
    descriptionId: "landing.action.settings.description",
    href: "/settings",
    icon: Cog6ToothIcon,
    iconForeground: "text-rose-700",
    iconBackground: "bg-rose-50",
  },
  {
    titleId: "landing.action.usage",
    descriptionId: "landing.action.usage.description",
    href: "/usage",
    icon: ChartBarSquareIcon,
    iconForeground: "text-emerald-700",
    iconBackground: "bg-emerald-50",
  },
];

export default function LandingActions() {
  return (
    <div className="divide-y divide-gray-300 overflow-hidden rounded-lg bg-gray-300 shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">
      {actions.map((action, actionIdx) => (
        <div
          key={action.titleId}
          className={joinClasses(
            actionIdx === 0
              ? "rounded-tl-lg rounded-tr-lg sm:rounded-tr-none"
              : "",
            actionIdx === 1 ? "sm:rounded-tr-lg" : "",
            actionIdx === actions.length - 2 ? "sm:rounded-bl-lg" : "",
            actionIdx === actions.length - 1
              ? "rounded-bl-lg rounded-br-lg sm:rounded-bl-none"
              : "",
            "group relative bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500"
          )}
        >
          <div>
            <span
              className={joinClasses(
                action.iconBackground,
                action.iconForeground,
                "inline-flex rounded-lg p-3 ring-4 ring-white"
              )}
            >
              <action.icon className="h-6 w-6" aria-hidden="true" />
            </span>
          </div>
          <div className="mt-8">
            <h3 className="text-base font-semibold leading-6 text-gray-900">
              <Link to={action.href} className="focus:outline-none">
                {/* Extend touch target to entire panel */}
                <span className="absolute inset-0" aria-hidden="true" />
                <FormattedMessage id={action.titleId} />
              </Link>
            </h3>
            <p className="mt-2 text-sm text-gray-500">
              <FormattedMessage id={action.descriptionId} />
            </p>
          </div>
          <span
            className="pointer-events-none absolute right-6 top-6 text-gray-300 group-hover:text-gray-400"
            aria-hidden="true"
          >
            <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
              <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
            </svg>
          </span>
        </div>
      ))}
    </div>
  );
}
