import { useState, useEffect, useContext } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { validateCategory } from "../../validation/CategoryValidation";
import { getInitialCategory } from "../../helpers/Utils";
import TextInput from "../../components/input/text/TextInput";
import LimitReached from "../../components/limitReached/LimitReached";
import TextAreaInput from "../../components/input/text/TextAreaInput";
import { UserContext } from "../../wrappers/user/UserDataWrapper";
import { HalfWidthPageWrapper } from "../../components/page-wrappers/PageWrappers";
import FormTitle from "../../components/form-title/FormTitle";
import useRestApi from "../../custom-hooks/useRestApi";

export default function AddCategoryPage() {
  const [category, setCategory] = useState(getInitialCategory());
  const [validationErrors, setValidationErrors] = useState({});
  const [limitReached, setLimitReached] = useState(false);
  const { categoryLimit } = useContext(UserContext);
  const navigate = useNavigate();
  const { executePostCall, executeGetCall } = useRestApi();
  const intl = useIntl();

  useEffect(() => {
    async function getCategoryCount() {
      let { body } = await executeGetCall("categories/count");
      return body;
    }

    async function initalLoad() {
      let categoryCount = await getCategoryCount();

      setLimitReached(
        categoryLimit !== Infinity && categoryCount >= categoryLimit
      );
    }

    initalLoad();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleSaveClick() {
    const validationResult = validateCategory(category, intl);

    if (!validationResult.isValid) {
      setValidationErrors(validationResult.validationErrors);
      return;
    }

    const { body } = await executePostCall("categories", {
      body: category,
    });
    navigate(`/categories/view/${body.sharedId}`, {
      state: {
        comingFromAddPage: true,
      },
    });
  }

  if (limitReached) {
    return (
      <HalfWidthPageWrapper>
        <LimitReached
          infoMessageKey="plan.limit.reached.categories.info"
          linkMessageKey="plan.limit.reached.link"
        />
      </HalfWidthPageWrapper>
    );
  } else {
    return (
      <HalfWidthPageWrapper>
        <form>
          <div className="space-y-10 sm:space-y-12">
            <FormTitle titleKey="category.add.new" />

            <div className="mt-10 space-y-8 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-300 sm:pb-0">
              <TextInput
                labelTextKey="category.name"
                id="category-name"
                validationError={validationErrors["name"]}
                value={category.name}
                handleOnChange={(event) => {
                  setValidationErrors({});
                  setCategory({
                    ...category,
                    name: event.target.value,
                  });
                }}
              />
              <TextAreaInput
                labelTextKey="Description"
                id="category-description"
                value={category.description}
                handleOnChange={(event) =>
                  setCategory({
                    ...category,
                    description: event.target.value,
                  })
                }
              />
            </div>
          </div>

          <div className="mt-6 flex items-center justify-end gap-x-6">
            <Link
              to="/categories"
              className="text-sm font-semibold leading-6 text-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              <FormattedMessage id="Cancel" />
            </Link>

            <button
              type="button"
              className="rounded-md bg-indigo-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={handleSaveClick}
            >
              <FormattedMessage id="Save" />
            </button>
          </div>
        </form>
      </HalfWidthPageWrapper>
    );
  }
}
