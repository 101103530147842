import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

export default function Table({
  title,
  items,
  prev,
  next,
  actions,
  labels,
  showAddButton = true,
}) {
  function mapItemToRow(item, idx) {
    return (
      <div
        key={idx}
        className="flex flex-col items-center sm:flex-row sm:justify-between sm:p-4"
      >
        <span className="text-sm font-medium py-4 sm:py-2 flex flex-row justify-around w-full sm:w-auto sm:justify-start">
          {labels(item).map((label, labelIdx) => (
            <span
              key={labelIdx}
              className={`font-semibold text-sm tracking-wide self-center px-2.5 ${label.classes}`}
            >
              {label.value}
            </span>
          ))}
        </span>
        <span className="text-sm font-medium py-4 sm:py-2 flex flex-row justify-around w-full sm:w-auto sm:justify-end">
          {actions(item).map((action, actionIdx) => (
            <span
              key={actionIdx}
              className={`justify-self-center self-center rounded-md px-2.5 py-1.5 font-semibold shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 hover:cursor-pointer sm:mx-6 ${action.textClasses}`}
            >
              {action.element}
            </span>
          ))}
        </span>
      </div>
    );
  }

  return (
    <div className="w-full my-2 lg:my-4">
      <div className="divide-y divide-gray-300 ring-1 ring-gray-300 rounded-md sm:mx-0 sm:rounded-lg">
        <div className="flex flex-row justify-between p-4 items-center border-b-2 border-gray-400">
          <span className="tracking-wide text-lg font-bold">{title}</span>
          {showAddButton && (
            <Link to={`add`}>
              <button
                type="button"
                className="block rounded-md bg-indigo-600 py-2 px-3 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                <FormattedMessage id="Add" />
              </button>
            </Link>
          )}
        </div>
        {items.map((item, idx) => mapItemToRow(item, idx))}
      </div>
      <Pagination prev={prev} next={next} />
    </div>
  );
}

function Pagination({ prev, next }) {
  if (!prev && !next) {
    return;
  }

  return (
    <div className="flex flex-1 justify-end p-4 sm:p-6" aria-label="Pagination">
      <button
        disabled={!prev}
        onClick={prev}
        className="relative inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-200 focus-visible:outline-offset-0 disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-500 disabled:ring-gray-300"
      >
        <FormattedMessage id="Previous" />
      </button>

      <button
        disabled={!next}
        onClick={next}
        className="relative ml-3 inline-flex items-center rounded-md p-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-200 focus-visible:outline-offset-0 disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-500 disabled:ring-gray-300"
      >
        <FormattedMessage id="Next" />
      </button>
    </div>
  );
}
