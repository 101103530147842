export function validateNotification(notification, intl) {
  let isValid = true;
  let validationErrors = {};

  if (!notification || !notification.name || notification.name.length <= 0) {
    isValid = false;
    validationErrors["name"] = intl.formatMessage({
      id: "notification.name.validation.empty",
    });
  }

  if (notification.categories.length === 0) {
    isValid = false;
    validationErrors["categories"] = intl.formatMessage({
      id: "notification.category.validation.empty",
    });
  }

  for (let idx = 0; idx < notification.categories.length; idx++) {
    let notificationCategory = notification.categories[idx];

    if (!notificationCategory || !notificationCategory.category) {
      isValid = false;
      validationErrors[`category-${idx}-name`] = intl.formatMessage({
        id: "category.validation.empty",
      });
    } else {
      if (
        !notificationCategory.category.name ||
        notificationCategory.category.name.length <= 0
      ) {
        isValid = false;
        validationErrors[`category-${idx}-name`] = intl.formatMessage({
          id: "category.validation.empty",
        });
      }

      if (!isInt(notificationCategory.number)) {
        isValid = false;
        validationErrors[`category-${idx}-number`] = intl.formatMessage({
          id: "category.number.validation.not.number",
        });
      }

      if (parseInt(notificationCategory.number) > 35) {
        isValid = false;
        validationErrors[`category-${idx}-number`] = intl.formatMessage({
          id: "category.number.validation.more.than.35",
        });
      }

      if (parseInt(notificationCategory.number) <= 0) {
        isValid = false;
        validationErrors[`category-${idx}-number`] = intl.formatMessage({
          id: "category.number.validation.less.than.1",
        });
      }
    }
  }

  if (
    !notification.sendMonday &&
    !notification.sendTuesday &&
    !notification.sendWednesday &&
    !notification.sendThursday &&
    !notification.sendFriday &&
    !notification.sendSaturday &&
    !notification.sendSunday
  ) {
    isValid = false;
    validationErrors["sendOn"] = intl.formatMessage({
      id: "send.on.validation.empty",
    });
  }

  return { isValid: isValid, validationErrors: validationErrors };
}

function isInt(value) {
  if (isNaN(value)) {
    return false;
  }
  var x = parseFloat(value);
  return (x | 0) === x;
}
