import { fetchAuthSession } from "aws-amplify/auth";
import { put } from "aws-amplify/api";
import { useIntl, FormattedMessage } from "react-intl";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { toast } from "react-toastify";
import Loading from "../../components/loading/Loading";
import { UserContext } from "../../wrappers/user/UserDataWrapper";
import FormTitle from "../../components/form-title/FormTitle";
import TextInput from "../../components/input/text/TextInput";
import { HalfWidthPageWrapper } from "../../components/page-wrappers/PageWrappers";
import validator from "validator";

export default function EditEmailPage() {
  const [previousEmail, setPreviousEmail] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [validationError, setValidationError] = useState({});
  const [loading, setLoading] = useState(false);
  const { email, refreshUser } = useContext(UserContext);
  const { showBoundary } = useErrorBoundary();
  const navigate = useNavigate();
  const intl = useIntl();

  useEffect(() => {
    if (email) {
      setPreviousEmail(email);
      setEmailInput(email);
    }
  }, [email]);

  function handleUpdateEmailClick() {
    if (!validator.isEmail(emailInput)) {
      setValidationError(
        intl.formatMessage({
          id: "e-mail.validation",
        })
      );
      return;
    } else {
      setLoading(true);
      updateUserEmail(previousEmail, emailInput);
    }
  }

  async function updateUserEmail(previousEmail, email) {
    try {
      const authToken = (await fetchAuthSession()).tokens?.idToken?.toString();

      const restOperation = put({
        apiName: "YumalarmRestAPI",
        path: "updateEmail",
        options: {
          headers: {
            Authorization: authToken,
          },
          body: {
            previousEmail: previousEmail,
            newEmail: email,
          },
        },
      });

      await restOperation.response;
    } catch (error) {
      showBoundary(error);
    }

    await refreshUser();

    toast.success(intl.formatMessage({ id: "updateEmail.success" }), {
      position: "bottom-right",
    });

    navigate("/settings");
  }

  if (loading) {
    return (
      <HalfWidthPageWrapper>
        <Loading />
      </HalfWidthPageWrapper>
    );
  } else {
    return (
      <HalfWidthPageWrapper>
        <form>
          <div className="space-y-10 sm:space-y-12">
            <FormTitle titleKey="e-mail.update" />

            <div className="mt-10 space-y-8 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-300 sm:pb-0">
              <TextInput
                labelTextKey="E-mail"
                id="e-mail"
                type="email"
                value={emailInput}
                handleOnChange={(event) => setEmailInput(event.target.value)}
                validationError={validationError}
              />
            </div>
          </div>

          <div className="mt-6 flex items-center justify-end gap-x-6">
            <Link
              to="/settings"
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              <FormattedMessage id="Cancel" />
            </Link>

            <button
              type="button"
              className="rounded-md bg-indigo-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={handleUpdateEmailClick}
            >
              <FormattedMessage id="update.e-mail" />
            </button>
          </div>
        </form>
      </HalfWidthPageWrapper>
    );
  }
}
