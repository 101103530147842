import { updatePassword } from "aws-amplify/auth";
import { useIntl, FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import FormTitle from "../../components/form-title/FormTitle";
import { HalfWidthPageWrapper } from "../../components/page-wrappers/PageWrappers";
import TextInput from "../../components/input/text/TextInput";

const validatePassword = (oldPassword, password, confirmPassword, intl) => {
  let isValid = true;
  let validationErrors = {};

  if (oldPassword.length === 0) {
    isValid = false;
    validationErrors["oldPassword"] = intl.formatMessage({
      id: "password.old.empty.validation",
    });
  }

  if (password.length === 0) {
    isValid = false;
    validationErrors["password"] = intl.formatMessage({
      id: "password.new.empty.validation",
    });
  }

  if (confirmPassword !== password) {
    isValid = false;
    validationErrors["confirmPassword"] = intl.formatMessage({
      id: "password.match.validation",
    });
  }

  return { isValid: isValid, validationErrors: validationErrors };
};

export default function EditSettingsPage() {
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const navigate = useNavigate();
  const { showBoundary } = useErrorBoundary();
  const intl = useIntl();

  function handleUpdatePasswordClick() {
    const validationResult = validatePassword(
      oldPassword,
      password,
      confirmPassword,
      intl
    );
    setValidationErrors(validationResult.validationErrors);

    if (!validationResult.isValid) {
      return;
    } else {
      updateUserPassword(oldPassword, password, showBoundary);
    }
  }

  async function updateUserPassword(oldPassword, newPassword) {
    updatePassword({ oldPassword, newPassword })
      .then(() => {
        toast.success(intl.formatMessage({ id: "updatePassword.success" }), {
          position: "bottom-right",
        });
        navigate("/settings");
      })
      .catch((error) => showBoundary(error));
  }

  return (
    <HalfWidthPageWrapper>
      <form>
        <div className="space-y-10 sm:space-y-12">
          <FormTitle titleKey="password.edit" />

          <div className="mt-10 space-y-8 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-300 sm:pb-0">
            <TextInput
              labelTextKey="password.old"
              id="oldPassword"
              type="password"
              value={oldPassword}
              handleOnChange={(event) => setOldPassword(event.target.value)}
              validationError={validationErrors["oldPassword"]}
            />

            <TextInput
              labelTextKey="password.new"
              id="password"
              type="password"
              value={password}
              handleOnChange={(event) => setPassword(event.target.value)}
              validationError={validationErrors["password"]}
            />

            <TextInput
              labelTextKey="password.new.confirm"
              id="confirmPassword"
              type="password"
              value={confirmPassword}
              handleOnChange={(event) => setConfirmPassword(event.target.value)}
              validationError={validationErrors["confirmPassword"]}
            />
          </div>
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6">
          <Link
            to="/settings"
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            <FormattedMessage id="Cancel" />
          </Link>

          <button
            type="button"
            className="rounded-md bg-indigo-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={handleUpdatePasswordClick}
          >
            <FormattedMessage id="update.password" />
          </button>
        </div>
      </form>
    </HalfWidthPageWrapper>
  );
}
