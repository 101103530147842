export function joinClasses(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function generateSharedItemId(id, sub) {
  return id + ":" + sub;
}

export function getItemId(id) {
  return id.split(":")[0];
}

export function getInitialCategory() {
  return {
    id: "",
    sharedId: "",
    name: "",
    description: "",
    owner: "",
    isShared: false,
  };
}

export function getInitialMeal() {
  return {
    id: "",
    isShared: false,
    sharedId: "",
    name: "",
    description: "",
    category: {
      id: "",
      sharedCategoryId: "",
      name: "",
    },
  };
}

export function getInitialNotification() {
  return {
    id: "",
    name: "",
    categories: [
      {
        category: {
          id: "",
          sharedCategoryId: "",
          name: "",
        },
        number: 1,
      },
    ],
    sendMonday: false,
    sendTuesday: false,
    sendWednesday: false,
    sendThursday: false,
    sendFriday: false,
    sendSaturday: false,
    sendSunday: false,
    paused: false,
  };
}
