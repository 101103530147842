import Hero from "../../components/landing/Hero";
import Features from "../../components/landing/Features";
import CallToAction from "../../components/landing/CallToAction";
import Pricing from "../../components/landing/Pricing";
import TourModal from "../../components/tour-modal/TourModal";
import { FormattedMessage } from "react-intl";
import LandingActions from "../../components/landing/LandingActions";
import SecondaryFeatures from "../../components/landing/SecondaryFeatures";
import {
  FullWidthPageWrapper,
  HalfWidthPageWrapper,
} from "../../components/page-wrappers/PageWrappers";
import FAQ from "../../components/faq/FAQ";
import { UserContext } from "./../../wrappers/user/UserDataWrapper";
import { useContext } from "react";

export default function HomePage() {
  const { isLoggedIn } = useContext(UserContext);

  if (isLoggedIn) {
    return (
      <HalfWidthPageWrapper>
        <div className="w-full flex flex-col items-center justify-between">
          <h2 className="mx-auto text-3xl font-bold text-gray-900 sm:truncate sm:text-4xl tracking-tight pb-12">
            <FormattedMessage id="welcome.back" />
          </h2>
          <TourModal />
          <LandingActions />
        </div>
      </HalfWidthPageWrapper>
    );
  } else {
    return (
      <FullWidthPageWrapper>
        <Hero />
        <Features />
        <SecondaryFeatures />
        <CallToAction />
        <Pricing />
        <FAQ />
      </FullWidthPageWrapper>
    );
  }
}
