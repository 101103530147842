import { useState } from "react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Combobox } from "@headlessui/react";
import { FormattedMessage } from "react-intl";
import { joinClasses } from "../../helpers/Utils";

export default function CategoryAutocomplete({
  labelKey = "Category",
  categories,
  onCategorySelected,
  selectedCategory,
  validationError,
}) {
  const [query, setQuery] = useState("");

  categories.sort((a, b) => {
    const lowerCaseNameA = a.name.toLowerCase();
    const lowerCaseNameB = b.name.toLowerCase();

    if (lowerCaseNameA > lowerCaseNameB) {
      return 1;
    } else if (lowerCaseNameA < lowerCaseNameB) {
      return -1;
    } else {
      return 0;
    }
  });

  const filteredCategories =
    query === ""
      ? categories
      : categories.filter((category) => {
          return category.name.toLowerCase().includes(query.toLowerCase());
        });

  let extra = " text-gray-900 ring-gray-300";

  if (validationError) {
    extra = " text-red-900 ring-red-300";
  }

  return (
    <Combobox
      as="div"
      className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6"
      value={selectedCategory}
      onChange={onCategorySelected}
    >
      <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
        <FormattedMessage id={labelKey} />
      </Combobox.Label>
      <div className="relative mt-2 sm:col-span-2 sm:mt-0">
        <Combobox.Input
          className={
            "block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 " +
            extra
          }
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(selectedCategory) => selectedCategory?.name}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>

        {filteredCategories.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredCategories.map((category) => (
              <Combobox.Option
                key={category.id}
                value={category}
                className={({ active }) =>
                  joinClasses(
                    "relative cursor-default select-none px-3 sm:px-4 py-2 sm:py-3 rounded-sm ",
                    active ? "bg-indigo-600 text-white" : "text-gray-900"
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span
                      className={joinClasses(
                        "block truncate",
                        selected && "font-semibold"
                      )}
                    >
                      {category.name}
                    </span>

                    {selected && (
                      <span
                        className={joinClasses(
                          "absolute inset-y-0 right-0 flex items-center pr-4",
                          active ? "text-white" : "text-indigo-600"
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
      <span
        className="sm:col-start-2 text-sm text-red-600"
        id="meal-category-error"
      >
        {validationError}
      </span>
    </Combobox>
  );
}
