import List from "../../components/list/List";
import { useIntl } from "react-intl";
import { HalfWidthPageWrapper } from "../../components/page-wrappers/PageWrappers";
import useRestApi from "../../custom-hooks/useRestApi";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { useState } from "react";

export default function ListNotificationsPage() {
  const { executeGetCall, executeDeleteCall } = useRestApi();
  const intl = useIntl();
  const [refreshKey, setRefreshKey] = useState(0);

  async function handleFetchItems(nextToken) {
    let queryParams = { limit: 10 };
    if (nextToken) {
      queryParams.token = nextToken;
    }

    let { body } = await executeGetCall("notifications", { queryParams });

    return {
      items: body.items,
      token: body.nextToken ? body.nextToken : null,
    };
  }

  async function removeNotificationWithId(notificationId) {
    if (!notificationId) {
      return;
    }
    await executeDeleteCall(`notifications/${notificationId}`);
    setRefreshKey((prevKey) => prevKey + 1);
  }

  const labels = (notification) => [
    {
      value: notification.name,
    },
  ];

  const actions = (notification) => [
    {
      element: (
        <Link to={"view/" + notification.id}>
          <FormattedMessage id="View" />
        </Link>
      ),
      textClasses: "text-indigo-600 hover:text-indigo-900",
    },
    {
      element: (
        <Link to={"edit/" + notification.id}>
          <FormattedMessage id="Edit" />
        </Link>
      ),
      textClasses: "text-indigo-600 hover:text-indigo-900",
    },
    {
      element: (
        <Link onClick={() => removeNotificationWithId(notification.id)}>
          <FormattedMessage id="Delete" />
        </Link>
      ),
      textClasses: "text-red-600 hover:text-red-900",
    },
  ];

  return (
    <HalfWidthPageWrapper>
      <List
        title={intl.formatMessage({ id: "Notifications" })}
        emptyListTitle={intl.formatMessage({
          id: "empty.list.notifications.title",
        })}
        emptyListDescription={intl.formatMessage({
          id: "empty.list.notifications.description",
        })}
        emptyListNewText={intl.formatMessage({
          id: "empty.list.notifications.new",
        })}
        fetchItemsMethod={handleFetchItems}
        actions={actions}
        labels={labels}
        refreshKey={refreshKey}
      />
    </HalfWidthPageWrapper>
  );
}
