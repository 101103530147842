import PricingAlternative from "../../components/landing/PricingAlternative";
import { FullWidthPageWrapper } from "../../components/page-wrappers/PageWrappers";

export default function SubscriptionPage() {
  return (
    <FullWidthPageWrapper>
      <PricingAlternative />
    </FullWidthPageWrapper>
  );
}
